/* App.css */
.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2e7d32; /* Couleur verte du logo */
  color: #ffd700; 
  font-family: 'Arial', 'sans-serif';
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  border: 2px solid #8b4513; /* Couleur marron du logo */
  padding: 20px;
  border-radius: 10px;
  background-color: #4caf50; /* Une nuance de vert similaire au logo */
}

.App-logo {
  width: 200px; /* Ajustez cette valeur pour la taille souhaitée */
  height: auto; /* Conserve le ratio de l'image */
  margin-bottom: 20px;
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.5rem;
  margin: 0;
}
